import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PermissionRolesService } from 'app/common/services/permission-roles.service';
import { PermissionService, UserService } from 'app/shared';

@Injectable({
  providedIn: 'root'
})
export class RoleRedirectGuard implements CanActivate {

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private permissionRolesService: PermissionRolesService,
    private userService: UserService,
    private permissionService: PermissionService,
  ) { }
  private roles: any;

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      await this.userService.getFullUserInfo().toPromise();
      // console.error('getFullUserInfo in main =>', resp);
      this.roles = this.activatedRoute.snapshot.data?.roles || {};
      console.log(this.roles);
      this.roles = {
        isManager: this.permissionRolesService.isManager(this.userService.flatClient),
        isAdmin: this.permissionRolesService.isAdmin(this.userService.flatClient),
        isDeveloper: this.permissionRolesService.isDeveloper(this.userService.flatClient),
        isTechnician: this.permissionRolesService.isTechnician(this.userService.flatClient),
        isRemoteExpert: this.permissionRolesService.isRemoteExpert(this.userService.flatClient)
      };
      console.log(this.roles);
      const roleLinksMap = {
        isRemoteExpert: [
          { routerLink: 'conference', labelKey: 'curriculum.main.conference' },
          { routerLink: 'courses', labelKey: 'curriculum.main.courses' }
        ],
        isTechnician: [
          { routerLink: 'courses', labelKey: 'curriculum.main.courses' }
        ],
        isManager: [
          { routerLink: 'content', labelKey: 'curriculum.main.content' },
          { routerLink: 'make-courses', labelKey: 'curriculum.main.make-courses' },
          { routerLink: 'home', labelKey: 'curriculum.main.home' }
        ],
        isAdmin: [
          {
            routerLink: 'management',
            labelKey: 'curriculum.main.administrate',
            subItems: [
              { routerLink: 'management/devices', labelKey: 'curriculum.main.devices' },
              { routerLink: 'management/users', labelKey: 'curriculum.main.users' },
              { routerLink: 'management/groups', labelKey: 'curriculum.main.groups' },
              { routerLink: 'management/competences', labelKey: 'curriculum.main.competences' }
            ]
          }
        ],
        isDeveloper: [
          { routerLink: 'content', labelKey: 'curriculum.main.content' },
          { routerLink: 'make-courses', labelKey: 'curriculum.main.make-courses' },
          { routerLink: 'home', labelKey: 'curriculum.main.home' }
        ]
      };

      let links = [];

      for (const role in this.roles) {
        if (this.roles[role] && roleLinksMap[role]) {
          links = this.sort(links);
          links.push(...roleLinksMap[role]);
        }
      }

      if (links.length === 0) {
        links = this.sort(links);
        links.push(
          { routerLink: 'courses', labelKey: 'curriculum.main.courses' },
        );
      }

      links = this.sort(links);

      console.log('Redirecting to:', links[links.length - 1].routerLink, state.url, links.length - 1, links.length, links);

      if (state.url === '/' || state.url === '' || state.url === '/curriculum') {
        await this.router.navigateByUrl(`/curriculum/${links[links.length - 1].routerLink}`);
        return false;
      }

      return true;

    } catch (err) {
      console.error('getFullUserInfo in main =>', err);
      return false;
    }
  }

  sort(event) {
    const sortOrder = [
      'curriculum.main.home',
      'curriculum.main.administrate',
      'curriculum.main.conference',
      'curriculum.main.make-courses',
      'curriculum.main.content',
      'curriculum.main.courses',
    ];
    event = event.filter((obj, index, self) =>
        index === self.findIndex((t) => (
          t.routerLink === obj.routerLink
        ))
    );
    return event.sort((a, b) => sortOrder.indexOf(b.labelKey) - sortOrder.indexOf(a.labelKey));
  }
}
